import anime from 'animejs'

anime({
  targets: '.shape-one #shape-one',
  d: [
    {
      value:
        'M4542.642,3435.732c49.4-65.306,94.84-93.7,240.705-73.316s12.948,134.431-49.119,183.757-127.742,14.047-178.819-20.917S4542.642,3435.732,4542.642,3435.732Z',
    },
    {
      value:
        'M4542.642,3435.732c49.4-65.306,94.84-93.7,240.705-73.316s12.948,134.431-49.119,183.757-127.742,14.047-178.819-20.917S4542.642,3435.732,4542.642,3435.732Z',
    },
  ],
  easing: 'easeInOutQuad',
  direction: 'alternate',
  duration: 8000,
  loop: true,
})

anime({
  targets: '.shape-two #shape-two',
  d: [
    {
      value:
        'M4542.642,3435.732c49.4-65.306,94.84-93.7,240.705-73.316s12.948,134.431-49.119,183.757-127.742,14.047-178.819-20.917S4542.642,3435.732,4542.642,3435.732Z',
    },
    {
      value:
        'M4574.341,3432.97c92-57.933,63.14-90.937,209.005-70.554s12.948,134.431-49.119,183.757-159,31.138-192.492-21.8S4574.341,3432.97,4574.341,3432.97Z',
    },
    {
      value:
        'M4542.642,3435.732c49.4-65.306,50.556-70.354,163.337-72.26s111.822,65.158,103.674,140.582-203.167,56.165-254.244,21.2S4542.642,3435.732,4542.642,3435.732Z',
    },
  ],
  easing: 'easeInOutQuad',
  direction: 'alternate',
  duration: 7000,
  delay: 500,
  loop: true,
})

anime({
  targets: '.shape-three #shape-three',
  d: [
    {
      value:
        'M4562.264,3404.358c53.015-70.088,91.982-24.521,146.264,24.143s79.678,77.951,15.662,134.848-122.879,8.208-161.926-16.912C4502.4,3505.664,4562.264,3404.358,4562.264,3404.358Z',
    },
    {
      value:
        'M4562.264,3404.358c53.015-70.088,138.577-28.016,146.264,24.143s79.678,77.951,15.662,134.848-122.879,8.208-161.926-16.912C4502.4,3505.664,4562.264,3404.358,4562.264,3404.358Z',
    },
    {
      value:
        'M4562.264,3404.358c123.6-75.215,137.38-18.864,151.5,35.353s22.63,119.684-24.416,136.337-88.033-4.49-127.08-29.61C4502.4,3505.664,4562.264,3404.358,4562.264,3404.358Z',
    },
  ],
  easing: 'easeInOutQuad',
  direction: 'alternate',
  duration: 9000,
  delay: 1000,
  loop: true,
})

anime({
  targets: '.shape-four #shape-four',
  d: [
    {
      value:
        'M4537.628,3406.722c31.444-41.57,60.369-59.643,153.218-46.669s8.242,85.57-31.266,116.968-81.312,8.941-113.825-13.314S4537.628,3406.722,4537.628,3406.722Z',
    },
    {
      value:
        'M4540.484,3397.189c35.11-43.9,52.854-42.412,145.7-29.437s12.9,77.872-26.606,109.27-101.628,6.626-123.975-18.43S4540.484,3397.189,4540.484,3397.189Z',
    },
  ],
  easing: 'easeInOutQuad',
  direction: 'alternate',
  duration: 7000,
  delay: 500,
  loop: true,
})

anime({
  targets: '.shape-five path',
  d: [
    {
      value:
        'M20.339,198.751c72.842,96.3,139.849,138.168,354.939,108.111S394.371,108.634,302.848,35.9,114.481,15.185,39.164,66.742,20.339,198.751,20.339,198.751Z',
    },
    {
      value:
        'M20.339,198.751c17.927,50.5-4.7,95.854,213.5,101.368S465.251,170.282,418.615,85.63,114.481,15.185,39.164,66.742,20.339,198.751,20.339,198.751Z',
    },
  ],
  easing: 'easeInOutQuad',
  direction: 'alternate',
  duration: 12000,
  delay: 700,
  loop: true,
})
