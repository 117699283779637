const COOKIE_CONSENT = 'cookieConsent'

document.addEventListener('DOMContentLoaded', () => {
  const cookieConsent = localStorage.getItem(COOKIE_CONSENT)

  const cookieContent = /*html*/ `
    <div class="cookie-consent" id="cookie">
      <p>🍪 We use cookies to optimise your experience. To find out more see our <a target="_blank" href="/privacy-policy">Privacy Policy</a>.</p>
      <div class="flex jc-center ml-5">
        <button id="cookie-accept" class="btn btn-light" id="cookie-accept">Got it!</button>
      </div>
    </div>
  `

  if (!cookieConsent) {
    document.body.innerHTML += cookieContent
  }

  document.addEventListener('click', (event) => {
    if (event.target.id === 'cookie-accept') {
      localStorage.setItem(COOKIE_CONSENT, true)
      document.getElementById('cookie').remove()
    }
  })
})
