let lastKnownScrollY = 0
let currentScrollY = 0
let ticking = false
let header = null

const headerClasses = {
  hidden: 'header--hidden',
  visible: 'header--visible'
}

const onScroll = () => {
  currentScrollY = window.pageYOffset
  requestTick()
}

const requestTick = () => {
  if (!ticking) {
    requestAnimationFrame(update)
  }
  ticking = true
}

const update = () => {
  if (currentScrollY < lastKnownScrollY) {
    if (currentScrollY === 0) {
      resetheader()
    }
    showheader()
  }
  else if (currentScrollY > lastKnownScrollY) { hideheader() }
  lastKnownScrollY = currentScrollY
  ticking = false
}

const showheader = () => {
  if (header.classList.contains(headerClasses.hidden)) {
    header.classList.remove(headerClasses.hidden)
    header.classList.add(headerClasses.visible)
  }
}

const resetheader = () => {
  header.classList.remove(headerClasses.hidden)
  header.classList.remove(headerClasses.visible)
}

const hideheader = () => {
  if (header.classList.contains(headerClasses.visible) || !header.classList.contains(headerClasses.hidden)) {
    header.classList.remove(headerClasses.visible);
    header.classList.add(headerClasses.hidden);
  }
}

window.onload = () => {
  header = document.getElementById('header')
  document.addEventListener('scroll', onScroll, false)
}
